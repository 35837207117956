<!-- 
	This is the sign up page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<div>

		<!-- Sign Up Image And Headings -->
		<div class="sign-up-header" style="background-image: url('images/bg-signup.jpg')">
			<div class="content">
				<h1 class="mb-5">Create An Account</h1>
				<p class="text-lg">Join Stargate Credit Union today. It's quick and easy to start banking with us.</p>
			</div>
		</div>
		<!-- / Sign Up Image And Headings -->
		
		<!-- Sign Up Form -->
		<a-card :bordered="false" class="card-signup header-solid h-full" :bodyStyle="{paddingTop: 0}">

			<p class="text-center my-25 font-semibold text-muted"></p>
			<a-form
				id="components-form-demo-normal-login"
				:form="form"
				class="login-form"
				@submit="handleSubmit"
			>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'first_name',
						{ rules: [{ required: true, message: 'Please input your first name!' }] },
						]"
						placeholder="First Name"
					>
					</a-input>
				</a-form-item>
        <a-form-item class="mb-10">
          <a-input
              v-decorator="[
						'last_name',
						{ rules: [{ required: true, message: 'Please input your last name!' }] },
						]"
              placeholder="Last Name"
          >
          </a-input>
        </a-form-item>
				<a-form-item class="mb-10">
					<a-input
						v-decorator="[
						'email',
						{ rules: [{ required: true, message: 'Please input your email!' }] },
						]"
						placeholder="Email"
					>
					</a-input>
				</a-form-item>
        <a-form-item class="mb-10">
          <a-input
              v-decorator="[
						'address',
						{ rules: [{ required: true, message: 'Please input your full address!' }] },
						]"
              placeholder="Address"
          >
          </a-input>
        </a-form-item>
        <a-form-item class="mb-10">
          <a-input
              v-decorator="[
						'zip_code',
						{ rules: [{ required: true, message: 'Please input your address Zip Code!' }] },
						]"
              placeholder="Zip Code"
          >
          </a-input>
        </a-form-item>
        <a-form-item class="mb-10">
          <a-input

              v-decorator="[
						'ssn',
						{ rules: [{ required: true, message: 'Please input your SSN!' }] },
						]"
              placeholder="SSN"
          >
          </a-input
              >
        </a-form-item>
				<a-form-item class="mb-10">
					<a-checkbox
						v-decorator="[
						'remember',
						{
							valuePropName: 'checked',
							initialValue: true,
						},
						]"
					>
						I agree the <a href="#" class="font-bold text-dark">Terms and Conditions</a>
					</a-checkbox>
				</a-form-item>
				<a-form-item>
          <div v-if="!isLoading">
					<a-button type="primary" block html-type="submit" class="login-form-button">
						Create Account
					</a-button>
          </div>
          <div v-else style="text-align: center">
            <a-spin size="large" />
          </div>
				</a-form-item>
        <a-alert v-if="successful" class="mb-10" message="Account created successfully. Login details has been sent to your email. If for some reason, you don't see the email, please check spam or contact support. You may now login!" type="success" show-icon />
        <a-alert v-if="emailExist" class="mb-10" message="Account with this email already exists!" type="info" show-icon />
        <a-alert v-if="failed" class="mb-10" message="An error occurred! Please try again later." type="error" show-icon />

      </a-form>
			<p class="font-semibold text-muted text-center">Already have an account? <router-link to="/login" class="font-bold text-dark">Log In</router-link></p>
		</a-card>
		<!-- / Sign Up Form -->

	</div>
</template>

<script>
let axios = require("../axios")

	export default ({
		data() {
			return {
        successful: false,
        emailExist: false,
        failed: false,
        isLoading: false
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
			// Handles input validation after submission.
			handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields((err, values) => {
					if ( !err ) {
            console.log('Received values of form: ', values) ;
            this.isLoading = true;
            let data = {
              "email": values.email,
              "fname": values.first_name,
              "lname": values.last_name,
              "address": values.address,
              "zip_code": values.zip_code,
              "ssn": values.ssn
            }
            this.submitForm(data)
            }
				});
			},
      async submitForm(data){
        this.emailExist = false
        this.successful = false
        this.failed = false
        try {
          const response = await axios.post("/create-account/", data)
          console.log(response)
          this.isLoading = false;
          if (response.data === "successful"){
            this.successful = true
            window.setTimeout(() => {
              this.$router.push("/")
            }, 30000)
          }else if(response.data === "Account Exists"){
            this.emailExist = true
          }else{
            this.failed = true
          }
        }catch (e) {
          console.log(e.response)
          this.failed = true
          this.isLoading = false;
        }
      }
		},
	})

</script>

<style lang="scss">
</style>